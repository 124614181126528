import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import { withPrefix } from 'gatsby'
import quickrefH from '../assets/images/quickref-h.png'
import quickrefV from '../assets/images/quickref-v.png'

const iframeStyle = {
  margin: '0',
  padding: '0',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  marginBottom: '2em',
}

const mainStyle = {
  overflow: 'hidden',
  padding: '0',
  marginBottom: '2rem',
}
// const asideLeft = {
//   float: 'left',
//   width: '50%',
// }
// const asideRight = {
//   float: 'right',
//   width: '50%',
// }
// const asideImg = {
//   maxWidth: '100%',
// }

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Future Transit, Thunder Bay Transit Services, tbtfuture.fyi" />
        <HeaderGeneric title="Future Transit System" />
        <div id="main" style={mainStyle}>
          <iframe
            style={iframeStyle}
            // src="http://167.99.183.20/tbay/"
            src={withPrefix('/leaflet/')}
            frameBorder="0"
            scrolling="no"
          />
          <div className="aside-left">
            <img src={quickrefV} className="aside-img" />
          </div>
          <div className="aside-right">
            <h2>Static maps</h2>
            <p>The proposed routes are also available as PDFs, below:</p>
            <ul className="no-bullets">
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-00-all.pdf')}>
                  All Routes
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-01.pdf')}>Route 1</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-02.pdf')}>Route 2</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-03.pdf')}>Route 3</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-07.pdf')}>Route 7</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-08.pdf')}>Route 8</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-09.pdf')}>Route 9</a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-10.pdf')}>
                  Route 10
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-12.pdf')}>
                  Route 12
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-13.pdf')}>
                  Route 13
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-14.pdf')}>
                  Route 14
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-17.pdf')}>
                  Route 17
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-18.pdf')}>
                  Route 18
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-19.pdf')}>
                  Route 19
                </a>
              </li>
              <li>
                <a href={withPrefix('/docs/tbtfuture-route-onDemand.pdf')}>
                  On-demand service
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Generic
