import React from 'react'
import { Link } from 'gatsby'

const BackButton = props => {
  return (
    <Link to="/" className="backButton">
      <i className="fa fa-chevron-circle-left" />
    </Link>
  )
}

export default BackButton
