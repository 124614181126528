import React from 'react'
import { Link } from 'gatsby'
import BackButton from './BackButton'

const HeaderGeneric = props => (
  <header id="header" className="header-2nd">
    <h1>
      <BackButton />
      {props.title}
    </h1>
  </header>
)

export default HeaderGeneric
